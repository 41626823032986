.container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
}

.chart-container {
    flex: 1;
}

.table-container {
    flex: 1;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}


.badge {
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 12px;
    display: inline-block;
    text-align: center;
    font-weight: 700;
}

